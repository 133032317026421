/* .profile-check:checked + .profile-type-img {
  border: 1px solid #000;
  border-radius: 100%;
} */
.profile-type-img {
  /* padding: 15px; */
  /* border: 5px solid #d9d9d959; */
  border-radius: 100%;
  cursor: pointer;
}
.profile-check:checked + .profile-type-img {
  font-weight: bold;
  /* border: 5px solid #bdbaba; */
  background-color: #101942;
  transition: 0.5s ease-in;
  /* border-radius: 100%; */
}
.profile-type-img {
}
.profile-check {
  position: absolute;
  width: 0;
}
