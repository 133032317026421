@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom css */

/* Profile*/
.profile-form div {
  margin: 1% 0;
  text-align: left;
  /* flex-direction: column;
  display: flex; */
}
.profile-form input,
.profile-form select {
  padding: 10px 20px;
  width: 80%;
  border-radius: 7px;
}

@media screen and (max-width: 440px) {
  .profile-form input,
  .profile-form select {
    padding: 10px 20px;
    width: 95%;
    border-radius: 7px;
  }
}
